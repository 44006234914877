/* WORDPRESS LOGIN */
$brand-primary: #000000;
$brand-info: #ed1c24;
$mainFont: "Arial",sans-serif;
$secondaryFont: "Arial",sans-serif;

.login {
	background: url('../img/login_bg.jpg') no-repeat center center / cover;
	font-family: $mainFont;
	
	h1 {
		a {
			background-image: url('../img/login_logo.png');
			background-repeat: no-repeat;
			background-size: 200px;
			height: 200px;
			width: 200px;
		}
	}
	form {
		background: $brand-primary;
		border: 2px solid $brand-info;
	}
	label {
		color: $brand-info;
		text-transform: uppercase;
		font-family: $mainFont;
	}
	input[type=text], input[type=password], input[type=checkbox] {
		font-family: $secondaryFont;
		
		&:focus {
			border-color: $brand-info;
		}
	}
	
	input[type=checkbox]:checked:before {
		color: $brand-info;
	}
	input#wp-submit {
		background: $brand-info !important;
		border-color: darken($brand-info,5%);
		box-shadow: none;
		text-shadow: none;
		transition: background .3s ease-in-out;
		color: #fff;
		text-transform: uppercase;
		
		&:hover {
			background: darken($brand-info,10%) !important;
		}
	}
	#backtoblog a, #nav a {
		transition: color 300ms ease;
		color: $brand-primary;
		
		&:hover {
			color: $brand-info;
		}
	}
}